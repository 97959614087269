.slick-arrow.slick-next,
.slick-arrow.slick-prev {
     background-color: #256E1D;
     height: 25px;
     width: 25px;
     display: flex;
     align-items: center;
     justify-content: center;
     border-radius: 50%;
     opacity: 0.8;
     z-index: 10;
     height: 30px;
     width: 30px;
}

.slick-arrow.slick-next:hover,
.slick-arrow.slick-prev:hover {
     background-color: #084701;
     opacity: 1;
}


.slick-prev::before,
.slick-next::before {
     display: none;

}

.arrows {
     height: 20px;
     width: 20px;
     margin: 1px;     
}