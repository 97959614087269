/* CSS for the confirmation animation */

.confirmation-container {
     display: flex;
     justify-content: center;
     align-items: center;
     height: 100vh;
     background-color: rgba(0, 0, 0, 0.5);
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     z-index: 999;
}

.confirmation-box {
     background-color: #fff;
     padding: 30px;
     text-align: center;
     border-radius: 10px;
     animation: slideIn 0.5s ease-in-out forwards;
}

.confirmation-icon {
     font-size: 48px;
     color: green;
     margin-bottom: 20px;
}

@keyframes slideIn {
     0% {
          transform: translateY(-100%);
          opacity: 0;
     }

     100% {
          transform: translateY(0);
          opacity: 1;
     }
}